<template>
  <div class="form registration">
    <div class="container">
      <h1 class="title">Sign up</h1>
      <form ref="ruleForm">
        <div class="input__box">
          <span class="label">Name</span>
          <input class="input" v-model="name" type="text" placeholder="Enter your name" />
        </div>
        <div class="input__box">
          <span class="label">Email</span>
          <input class="input" v-model="email" type="email" placeholder="Enter your email" />
        </div>
        <div class="input__box">
          <span class="label">Password</span>
          <div>
            <input class="input" v-model="password" :type="isShowPassword ? 'text' : 'password'"
              placeholder="Enter your password" />
            <TogglePassword :isshow="isShowPassword" :toggle="togglePassword" />
          </div>
        </div>

        <div class="error_message" v-if="password_message">
          <img :src="require('@/assets/static/images/error-message.svg')" alt="Logo">
          <span>
            {{ password_message }}
          </span>
        </div>
        <div class="form_footer">
          <button @click.prevent="submitForm('ruleForm')" class="auth-btn" :disabled="status === 'pending'">
            <span>Sign up with email</span>
            <div class="auth-pending-loader" v-if="status === 'pending'"></div>
          </button>
          <PrivacyPolicy />
          <p class="to-signin">
            Already signed up?
            <router-link to="/login">Sign in</router-link>
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import passwordValidator from "password-validator"
import PrivacyPolicy from "../components/auth/PrivacyPolicy.vue";
import TogglePassword from '../components/auth/TogglePassword.vue';

import { FCM } from '@capacitor-community/fcm'

export default {
  name: "Registration",
  props: {
    span: [Number],
  },
  data() {
    return {
      name: '',
      email: '',
      password: '',
      password_message: '',
      list_id: '',
      user_id: '',
      chat_id: '',
      status: '',
      googleShow: false,
      isShowPassword: false,
      isPasswordValid: false,
    };
  },
  async mounted() {

    if (this.$router.history.current.query.code) {
      let data = await this.InvitationDecrypt(this.$router.history.current.query.code)
      this.email = data.email
      this.list_id = data.list_id
      this.user_id = data.user_id
      this.chat_id = data.chat_id
      this.googleShow = false
      document.querySelector('.register_email input').setAttribute('disabled', '')
    }
  },
  methods: {
    ...mapActions(["register", "InvitationDecrypt"]),
    //REGISTER

    async submitForm(formName) {
      debugger
      if (!this.name) {
        this.password_message = "Enter name"
      } else if (this.name.length < 3 || this.name.length > 25) {
        this.password_message = "Name length should be 3 to 25";
      } else if (!this.email) {
        this.password_message = "Enter email"
      } else if (!String(this.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
        this.password_message = "Enter your email correctly"
      } else if (!this.password) {
        this.password_message = "Enter password"
      } else if (this.isPasswordValid) {
        this.password_message = '';
        this.status = 'pending';
        let firebaseToken = "";
        try {
          firebaseToken = await FCM.getToken();
        } catch (e) {
          alert("Something went wrong while generating firebase token");
        }
        const res = {
          url: this.$w_api.SIGN_UP,
          body: {
            email: this.email,
            password: btoa(this.password),
            name: this.name,
            color: this.$w_user_color[Math.floor(Math.random() * 37)],
            list_id: this.list_id,
            user_id: this.user_id,
            chat_id: this.chat_id,
            token: firebaseToken.token
          },
          //присылает токен, посмотреть данные объекта
        };

        const req = await this.register(res);

        if (req) {
          this.status = 'success'
          this.name = "";
          this.email = "";
          this.password = "";
          window.location.href = '/'

        } else {
          this.status = 'error'
        }
      }
    },
    togglePassword() {
      this.isShowPassword = !this.isShowPassword;
    }
  },
  watch: {
    password(e) {
      if (this.isPasswordValid) {
        this.isPasswordValid = false;
      }
      // console.log(this.$w_password)
      if (e == '') {
        this.password_message = 'Enter password.'
      } else {
        var schema = new passwordValidator()
        schema
          .is().min(8)
          .is().max(100)
          .is().not().oneOf([
            'Passw0rd', 'Password123',
            '123456',
            'password',
            '12345678',
            'qwerty',
            '123456789',
            '12345',
            '1234',
            '111111',
            '1234567',
            'dragon',
            '123123',
            'baseball',
            'abc123',
            'football',
            'monkey',
            'letmein',
            '696969',
            'shadow',
            'master',
            '666666',
            'qwertyuiop',
            '123321',
            'mustang',
            '1234567890',
            'michael',
            '654321',
            'pussy',
            'superman',
            '1qaz2wsx',
            '7777777',
            'fuckyou',
            '121212',
            '000000',
            'qazwsx',
            '123qwe',
            'killer',
            'trustno1',
            'jordan',
            'jennifer',
            'zxcvbnm',
            'asdfgh',
            'hunter',
            'buster',
            'soccer',
            'harley',
            'batman',
            'andrew',
            'tigger',
            'sunshine',
            'iloveyou',
            'fuckme',
            '2000',
            'charlie',
            'robert',
            'thomas',
            'hockey',
            'ranger',
            'daniel',
            'starwars',
            'klaster',
            '112233',
            'george',
            'asshole',
            'computer',
            'michelle',
            'jessica',
            'pepper',
            '1111',
            'zxcvbn',
            '555555',
            '11111111',
            '131313',
            'freedom',
            '777777',
            'pass',
            'fuck',
            'maggie',
            '159753',
            'aaaaaa',
            'ginger',
            'princess',
            'joshua',
            'cheese',
            'amanda',
            'summer',
            'love',
            'ashley',
            '6969',
            'nicole',
            'chelsea',
            'biteme',
            'matthew',
            'access',
            'yankees',
            '987654321',
            'dallas',
            'austin',
            'thunder',
            'taylor',
            'matrix'
          ])
        let valid = schema.validate(e, { list: true })
        if (valid.length > 0) {
          if (valid[0] == 'min') {
            this.password_message = 'Minimum allowed number of characters 8'
          }
          if (valid[0] == 'max') {
            this.password_message = 'Maximum allowed number of characters 100'
          }
          if (valid[0] == 'uppercase') {
            this.password_message = 'Must be a big symbol'
          }
          if (valid[0] == 'lowercase') {
            this.password_message = 'It should be small character'
          }
          if (valid[0] == 'digits') {
            this.password_message = 'It should be number character'
          }
          if (valid[0] == 'spaces') {
            this.password_message = "Shouldn't be spaces symbol"
          }
          if (valid[0] == 'oneOf') {
            this.password_message = 'This password is too easy to guess. Try using something stronger.'
          }
        } else {
          this.password_message = '';
          if (!this.isPasswordValid) {
            this.isPasswordValid = true;
          }
        }
      }
    }
  },
  components: {
    PrivacyPolicy,
    TogglePassword
  }
};
</script>
