<template>
    <div @click.stop="toggle" class="togglePassword">
        <img v-if="!isshow" :src="require('@/assets/static/images/hide-password.svg')" alt="">
        <img v-if="isshow" :src="require('@/assets/static/images/show-password.svg')" alt="">
    </div>
</template>

<script>

export default {
    props: ['isshow', 'toggle'],
}
</script>


<style scoped>
.togglePassword {
    position: absolute;
    right: 5px;
    top: 21px;
    cursor: pointer;
}

svg {
    width: 28px;
    height: 28px;
}
</style>